body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.header {
  background-color: #1a1a1a;
  height: 60px;
  color: #fff;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
  color: #fff;
  font-size: 1.8em;
}

/* Media query for responsive design */
@media only screen and (max-width: 768px) {
  .header .menu {
    display: flex;
  }
}

.section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

h1 {
  color: #333;
  font-size: 2.5em;
}

p {
  color: #555;
  line-height: 1.6;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.image-container {
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  display: block;
}

.image-container img:hover {
  transform: scale(1.05);
}

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 2px solid #555;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-content {
  position: relative;
  padding: 30px;
  background: #fff;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

/* Add a smooth transition for the modal content */
.modal-content img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
}